import { useMemo } from 'react';

import { Check, UICheck, Section, CheckStatus } from '../types';

import { mainComponentKeyToComponentMap, statusComponentKeyToComponentMap } from './constants';

import { JitText } from 'components/JitText/JitText';
import { useTenantContext } from 'context/TenantContext';
import color from 'themes/colors.module.scss';

const sortChecks = (checks: Check[]) => checks.sort((a, b) => a.order - b.order);
const sortSections = (sections: Section[]) => sections.sort((a, b) => a.order - b.order);

const createChecksBySection = (sortedChecks: Check[]) => {
  const checksBySection = {} as Record<Section['sectionSlug'], Section['checks']>;
  sortedChecks.forEach((check) => {
    (checksBySection[check.section] ||= []).push(check.checkSlug);
  });
  return checksBySection;
};

const createNormalizedData = (sortedSections: Section[], sortedChecks: Check[]) => {
  const checksBySection = createChecksBySection(sortedChecks);
  return {
    sections: sortedSections.map((section) => ({
      ...section,
      checks: checksBySection[section.sectionSlug] || [],
    })),
    checks: sortedChecks,
  };
};

const getStatusUIComponent = (check: Check) => {
  if (!check.statusComponent) {
    const text = check.status === CheckStatus.COMPLETED ? check.texts.statusCompleted : check.texts.statusNotCompleted;
    const textColor = check.status === CheckStatus.COMPLETED ? color.successGreen02 : color.lightGray;
    return () => <JitText bold color={textColor} text={text} />;
  }

  return statusComponentKeyToComponentMap[check.statusComponent];
};

const createChecksMap = (normalizedChecks: Check[]) => {
  const map = {} as Record<Check['checkSlug'], Check & UICheck>;

  normalizedChecks.forEach((check) => {
    const mainUIComponent = mainComponentKeyToComponentMap[check.mainComponent];
    const statusUIComponent = getStatusUIComponent(check);

    const updatedCheck = {
      ...check,
      isVisible: !check.isHidden && (!check.dependsOn || map[check.dependsOn]?.status === CheckStatus.COMPLETED),
      mainUIComponent,
      statusUIComponent,
    };
    map[check.checkSlug] = updatedCheck;
  });

  return map;
};

export const useQuickstartGuide = () => {
  const { checks, sections, isChecksLoading } = useTenantContext();
  const sortedChecks = useMemo(() => sortChecks(checks), [checks]);
  const sortedSections = useMemo(() => sortSections(sections), [sections]);
  const normalizedData = useMemo(
    () => createNormalizedData(sortedSections, sortedChecks),
    [sortedSections, sortedChecks],
  );

  const checksMap = useMemo(
    () => createChecksMap(normalizedData.checks),
    [normalizedData.checks],
  );

  return {
    data: normalizedData,
    checksMap,
    isLoading: isChecksLoading,
  };
};
