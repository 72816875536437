import { FC, useMemo } from 'react';

import { BookTimeWidget } from './components/BookTimeWidget/BookTimeWidget';
import { QuickGuideSection } from './components/QuickGuideSection/QuickGuideSection';
import { useQuickstartGuide } from './hooks';
import styles from './QuickStartGuide.module.scss';
import { CheckStatus } from './types';

import { CompletionStatusRow } from 'components/CompletionStatusRow/CompletionStatusRow';
import { Background } from 'components/Pages/Background/Background';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';

export const QuickStartGuide: FC = () => {
  const { checksMap, data } = useQuickstartGuide();

  const nonHiddenChecks = useMemo(() => data.checks.filter(({ isHidden }) => !isHidden), [data.checks]);
  const totalChecks = useMemo(() => nonHiddenChecks.length, [nonHiddenChecks]);
  const completedChecks = useMemo(() => nonHiddenChecks.filter(({ status }) => status === CheckStatus.COMPLETED).length, [nonHiddenChecks]);
  const lastSectionSlug = useMemo(() => data.sections[data.sections.length - 1].sectionSlug, [data.sections]);
  const renderedSections = useMemo(() => data.sections.map((section) => (
    <QuickGuideSection
      key={section.sectionSlug}
      checks={section.checks}
      checksMap={checksMap}
      isLastSection={lastSectionSlug === section.sectionSlug}
      title={section.display}
    />
  )), [checksMap, data.sections, lastSectionSlug]);

  return (
    <PageLayout>
      <div className={styles.root}>
        <Background />

        <div className={styles.container}>
          <BookTimeWidget />

          <CompletionStatusRow completed={completedChecks} description='pages.quickStart.description' title='pages.quickStart.title' total={totalChecks} />

          <div className={styles.sections}>
            {renderedSections}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
