import React, { useState, useMemo, useEffect, useCallback } from 'react';

import { useQuickGuideSectionComponents, rightStyle } from '../../hooks/useQuickGuideSectionComponents';

import styles from './QuickGuideSection.module.scss';

import { PanelLayout } from 'components/PanelLayout';
import { UICheck, Check, CheckStatus } from 'pages/QuickStartGuide/types';
import { BaseTaskGuide } from 'pages/TeamsPortal/GuidePage/components/BaseTaskGuide/BaseTaskGuide';
import colors from 'themes/colors.module.scss';

interface Props {
  isLastSection: boolean;
  title: string;
  checks: string[];
  checksMap: Record<string, UICheck & Check>;
}

export const QuickGuideSection: React.FC<Props> = ({ title, checks, checksMap, isLastSection }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const visibleChecks = useMemo(
    () => checks.map((checkSlug) => checksMap[checkSlug]).filter((check) => check.isVisible),
    [checks, checksMap],
  );

  const isAllCompleted = useMemo(
    () => !!visibleChecks.length && visibleChecks.every((check) => check.status === CheckStatus.COMPLETED),
    [visibleChecks],
  );

  const isDisabled = useMemo(() => !visibleChecks.length, [visibleChecks]);
  const startsCollapsed = useMemo(() => isAllCompleted && !isLastSection, [isAllCompleted, isLastSection]);

  useEffect(() => {
    setIsExpanded(!isDisabled && !startsCollapsed);
  }, [isDisabled, isAllCompleted, startsCollapsed]);

  const sectionColor = useMemo(
    () => (isDisabled ? colors.lightGray : colors.iris),
    [isDisabled],
  );

  const toggleExpand = useCallback(() => {
    if (!isDisabled) {
      setIsExpanded((prev) => !prev);
    }
  }, [isDisabled]);

  const { rowComponents } = useQuickGuideSectionComponents(
    isExpanded,
    sectionColor,
    title,
    isAllCompleted,
  );

  const toggleButtonClass = !isDisabled ? styles.toggleButton : '';

  return (
    <div className={`${styles.sectionWrapper} ${isDisabled ? styles.disabled : ''}`} data-testid='quick-guide-section'>
      <div className={toggleButtonClass} data-testid='quick-guide-button' onClick={toggleExpand} role='button' tabIndex={isDisabled ? -1 : 0}>
        <PanelLayout rowComponents={rowComponents} />
      </div>

      {isExpanded && (
        <div className={styles.checkList}>
          {visibleChecks.map((check) => (
            <div key={check.checkSlug} className={styles.checkItem} data-testid={`quick-guide-section-check-${check.checkSlug}`}>
              <BaseTaskGuide
                description={check.texts.description}
                isCompleted={check.status === CheckStatus.COMPLETED}
                MainComponent={check.mainUIComponent}
                RightComponent={check.statusUIComponent}
                styles={{ rightStyle }}
                title={check.texts.title}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
