import { t } from 'i18next';
import debounce from 'lodash/debounce';
import { FC, UIEvent, useCallback, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { ValidationError } from './ChooseGroup';
import styles from './ChooseGroup.module.scss';

import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { calcShouldFetchMore } from 'components/JitTable/utils';
import { JitText } from 'components/JitText/JitText';
import { useTenantContext } from 'context/TenantContext';
import { IGroup, useGitlabService } from 'services/GitlabService/useGitlabService';
import { Queries } from 'types/enums/Queries';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

interface Props {
  minAccessLevel: number;
  setSelectedGroup: (group: IGroup) => void;
  selectedGroup?: IGroup;
  setValidationError: (error: ValidationError) => void;
  isButtonLoading: boolean;
}

const PAGE_LIMIT = 100;
const GROUP_SEARCH_BOX_PLACEHOLDER = t('GitlabIntegrationWizard.chooseGitlabGroup.dropdown.placeholder');

export const GroupsDropdown: FC<Props> = ({ minAccessLevel, setSelectedGroup, selectedGroup, setValidationError, isButtonLoading }) => {
  const [groupsSearchTerm, setGroupsSearchTerm] = useState<string>();
  const { fetchGroups } = useGitlabService();
  const { gitlabInstallation } = useTenantContext();
  const [isDisabled, setIsDisabled] = useState(false);

  const setGroupsValidationError = useCallback((status: number) => {
    setValidationError({ status,
      action: 'load groups' });
  }, [setValidationError]);

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading, remove } = useInfiniteQuery(
    [Queries.GitlabGroups, groupsSearchTerm],
    async ({ pageParam = 1 }) => {
      const response = await fetchGroups({
        per_page: PAGE_LIMIT,
        page: pageParam,
        search: groupsSearchTerm,
        min_access_level: minAccessLevel,
      });

      if (response && response.status !== 200) {
        setGroupsValidationError(response.status);
        setIsDisabled(true);
        return undefined;
      }
      return camelizeSnakeCaseKeys(response?.data.groups || []) as IGroup[];
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage || lastPage.length < PAGE_LIMIT) return undefined;
        return pages.length + 1;
      },
      enabled: !gitlabInstallation,
      onError: () => {
        setGroupsValidationError(400);
        setIsDisabled(true);
      },
    },
  );

  const groups = data?.pages.reduce((acc, currPage) => acc?.concat(currPage || []), []) || [];

  const onScroll = useCallback((e: UIEvent<HTMLUListElement>) => {
    if (hasNextPage && !isFetchingNextPage && calcShouldFetchMore(e, 20)) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const debouncedInputChange = debounce((value: string) => {
    if (selectedGroup?.name !== value) {
      setGroupsSearchTerm(value);
      remove();
    }
  }, 300);

  return (
    <div className={styles.groupsDropdown}>
      <JitText bold size='l' text='GitlabIntegrationWizard.chooseGitlabGroup.title' />

      <JitAutoCompleteForm
        clearOnBlur={false}
        disableCloseOnSelect
        disabled={!!gitlabInstallation || isDisabled || isButtonLoading}
        fontSize={14}
        getOptionKey={(group) => group.id}
        getOptionLabel={(group) => group.name}
        isLoading={isLoading || isFetchingNextPage}
        isSingleValue
        onInputChange={debouncedInputChange}
        onScroll={onScroll}
        options={groups}
        placeHolder={GROUP_SEARCH_BOX_PLACEHOLDER}
        selectedValue={selectedGroup}
        setSelectedValue={setSelectedGroup}
      />
    </div>
  );
};
